.pdf_reader {
    height: 100%;
    max-height: 90vh;
    /* border: 1px solid red; */
    /* padding: 10px; */
    /* overflow: auto; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 386px;
}

.pdf_reader canvas {
    width: 100% !important;
    height: auto !important;
}

.pdf_reader_page_number {
    margin: 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--primary);
}

.pdf_reader_menu {
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0; */
    background-color: white;
    padding: 10px;
    border-top: 1px solid #e0e0e0;

    /* border: 1px solid red; */
}

.pdf_reader_menu button {
    /* margin: 10px 0; */
    border-radius: 0;
}

.pdf_reader_menu_download {
    /* margin-left: 10px; */
    border-radius: 0;
}

.pdf_reader_document {
    /* position: absolute;
    top: 0;
    left: 0; */
    /* max-width: 100%; */
    /* max-height: 360px; */
    width: 100%;
    overflow: auto;
    /* border: 1px solid red; */
}

.pdf_reader--error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    text-align: center;
    padding: 20px;
    background: var(--light-gray);
    border-radius: 8px;
}

.pdf_reader--error h2 {
    color: var(--primary);
    margin-bottom: 12px;
}

.pdf_reader--error p {
    color: var(--secondary);
}

@media (max-width: 801px) {
    .pdf_reader_page_number button {
        /* font-size: 1.2rem; */
        margin-top: 10px;
    }
}
