.quiz {
    padding: 40px;
    display: flex;
    flex-direction: column;

    position: relative;

    gap: 20px;
    height: 90vh;
    /* margin-bottom: 20px; */
}

.quiz__content {
    border: 1px solid var(--primary-60);

    padding: 14px 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    /* margin-bottom: 20px; */
}

.quiz__content-title {
    font-size: 18px;
    color: var(--primary);
    font-weight: 800;
}

.quiz__content-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.quiz__content-item p {
    font-size: var(--font-size-14);
    color: var(--secondary);
    font-weight: 500;
    margin-bottom: 0;
}

.quiz-answer {
    width: 100%;
    padding: 10px;
    /* margin: 5px 0; */
    cursor: pointer;
    /* border: 1px solid var(--primary-60); */
    transition:
        background-color 0.1s,
        color 0.1s;
    box-sizing: border-box;
    font-weight: 600;
}

.quiz-answer.selected {
    color: var(--secondary); /* Blue for selected answer */
}

.quiz-answer.correct {
    color: limegreen; /* Green for correct answer */
    cursor: default; /* Disable cursor to indicate no further interaction */
    font-weight: bold;
}

.quiz-answer.incorrect {
    color: var(--error); /* Red for incorrect answer */
    cursor: default; /* Disable cursor to indicate no further interaction */
    font-weight: bold;
}

.quiz-answer:hover {
    background-color: #e4fffb; /* Light gray for hover effect */
}

.quiz-answer.correct:hover,
.quiz-answer.incorrect:hover {
    background-color: transparent; /* No hover effect after submission */
}

.quiz__menu-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    /* border: 1px solid red; */
}

.quiz__content-rates {
    display: flex;
    gap: 12px;
    margin-top: 16px;
    /* border: 1px solid red; */
}

.quiz__content-rates-icon,
.quiz__content-menu-icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    transition: all 0.3s ease;
    border: 1px solid var(--primary-60);
}

.quiz__content-rates-icon:hover,
.quiz__content-menu-icon:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: scale(1.1);
}

.quiz__content-rates-icon.active,
.quiz__content-menu-icon.active {
    background-color: var(--primary);
}

.quiz__generate-more-button,
.quiz__check-button {
    border-radius: 40px;
    width: 100%;
    max-width: 644px;
    padding: 8px;
    font-size: var(--font-size-14);

    margin: 0 auto;
}

.quiz__score {
    width: 100%;
    text-align: center;
    /* text-transform: uppercase; */
    font-size: var(--font-size-18);
    font-weight: 800;
    color: var(--primary);
    /* margin-bottom: 20px; */
}

.block-20 {
    height: 10px;
    width: 100%;
}
